












import Vue from "vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Helpers from "./helpers";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Footer,
  },
});

// Preload heavy images or gifs that are used in other pages
Helpers.preloadImages(["img/bg.jpg"]);
