



























import Vue from "vue";
import { Slide } from "vue-burger-menu";
export default Vue.extend({
  name: "Header",
  components: {
    Slide,
  },
});
